import MobileDetect from "mobile-detect";
import { ReadonlyHeaders } from "next/dist/server/web/spec-extension/adapters/headers";

export default (headers: ReadonlyHeaders) => {
  const userAgent = headers.get("user-agent");

  if (!userAgent) return undefined;

  const md = new MobileDetect(userAgent);

  return Boolean(md.mobile());
};
