import config from "./config";

const getBaseRoute = (isAbsolute?: boolean) => {
  return isAbsolute ? config.NEXT_PUBLIC_PUBLIC_FRONTEND : "";
};

const publicRouteManager = {
  home(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/`;
  },

  business(businessId?: string, isAbsolute?: boolean) {
    if (!businessId) return "";

    return `${getBaseRoute(isAbsolute)}/empresa/${businessId}`;
  },

  emailPreferences(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/preferencias-de-correo`;
  },

  job(jobId: string, isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/empleo/${jobId}`;
  },

  jobs(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/empleos/`;
  },

  landingStudent(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/estudiantes`;
  },

  landingRecruiter(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/reclutadores`;
  },

  privacyNotice(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/aviso-de-privacidad`;
  },

  recruiter(userRecruiterId?: string, isAbsolute?: boolean) {
    if (!userRecruiterId) return "";

    return `${getBaseRoute(isAbsolute)}/reclutador/${userRecruiterId}`;
  },

  termsAndConditions(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/terminos-y-condiciones`;
  },

  thirdPartyJob(thirdPartyJobId: string, isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/empleo-externo/${thirdPartyJobId}`;
  },

  thirdPartyJobRedirect(thirdPartyJobId: string, isAbsolute?: boolean) {
    return `${getBaseRoute(
      isAbsolute
    )}/abrir-empleo-externo/${thirdPartyJobId}`;
  },
};

export default publicRouteManager;
