const externalLinks = {
  publicDemoTypeFormLink: "https://83h9kyw3ln9.typeform.com/to/jlpF4xSC",
  billingTypeFormLink: "https://83h9kyw3ln9.typeform.com/to/Sne3TG70",
  userStudentSupportKobleTypeFormLink:
    "https://83h9kyw3ln9.typeform.com/to/dQYW0ttK#user_student_id=",
  userRecruiterSupportKobleTypeFormLink:
    "https://83h9kyw3ln9.typeform.com/to/dQYW0ttK#user_recruiter_id=",
};

export default externalLinks;
